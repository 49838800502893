import React from 'react'
import { Link } from 'gatsby'
import * as images from 'images/home'
import Helmet from 'react-helmet'
import { heroKlantenservice } from 'images/hulp/klantenservice'
import Layout from '../../layouts'

import Checkmark from 'components/misc/checkmark'
const {
  logos: {
    woodwatch,
    petossi,
    storageShare,
    codaisseur,
  }
} = images

class SalarisadministratieKantorenPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if(id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="demo animated fadeInPage">

          <Helmet>
            <title>Salarisadministratie door heel Nederland | Employes</title>
            <meta name="description" content="Waar je ook bent, je kunt altijd gebruik maken van Employes. We bieden een online systeem waarmee je overal toegang hebt tot je salarisverwerking." />
            <meta itemprop="name" content="Salarisadministratie door heel Nederland | Employes" />
            <meta itemprop="description" content="Waar je ook bent, je kunt altijd gebruik maken van Employes. We bieden een online systeem waarmee je overal toegang hebt tot je salarisverwerking."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="header padding-xl">
            <div className="container-md relative">
              <div className="grid yg align-middle margin-l-bottom center text-center">
                <div className="col-12">
                  <div className="title margin-s-bottom">
                    <h5 className="eyebrow turq no-margin">Overzicht locaties</h5>
                    <h1>Salarisadministratie door heel Nederland</h1>
                    <p>Waar je ook bent, je kunt altijd gebruik maken van Employes. We bieden een online systeem waarmee je overal toegang hebt tot je salarisverwerking.</p>
                  </div>
                </div>
              </div>
              <div className="grid between features margin-l-bottom">
                <div className="col-4 feature">
                  <h5>A-E</h5>
                  <Link className="link mulberry" to="/salarisadministratie-almelo/">Salarisadministratie Almelo </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-almere/">Salarisadministratie Almere </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-alkmaar/">Salarisadministratie Alkmaar </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-alphen-aan-den-rijn/">Salarisadministratie Alphen aan den Rijn </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-amersfoort/">Salarisadministratie Amersfoort </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-amstelveen/">Salarisadministratie Amstelveen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-amsterdam/">Salarisadministratie Amsterdam </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-apeldoorn/">Salarisadministratie Apeldoorn </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-arnhem/">Salarisadministratie Arnhem </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-assen/">Salarisadministratie Assen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-bergen-op-zoom/">Salarisadministratie Bergen op Zoom </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-breda/">Salarisadministratie Breda </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-capelle-aan-den-ijssel/">Salarisadministratie Capelle aan den IJssel </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-delft/">Salarisadministratie Delft </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-den-bosch/">Salarisadministratie Den Bosch </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-den-haag/">Salarisadministratie Den Haag </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-den-helder/">Salarisadministratie Den Helder </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-deventer/">Salarisadministratie Deventer </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-doetinchem/">Salarisadministratie Doetinchem </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-dordrecht/">Salarisadministratie Dordrecht </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-ede/">Salarisadministratie Ede </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-eindhoven/">Salarisadministratie Eindhoven </Link><br /><br />
                </div>
                <div className="col-4 feature">
                  <h5>E-M</h5>
                  <Link className="link mulberry" to="/salarisadministratie-emmen/">Salarisadministratie Emmen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-enschede/">Salarisadministratie Enschede </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-geleen/">Salarisadministratie Geleen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-gouda/">Salarisadministratie Gouda </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-groningen/">Salarisadministratie Groningen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-haarlem/">Salarisadministratie Haarlem </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-heerenveen/">Salarisadministratie Heerenveen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-heerlen/">Salarisadministratie Heerlen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-helmond/">Salarisadministratie Helmond </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-hengelo/">Salarisadministratie Hengelo </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-hilversum/">Salarisadministratie Hilversum </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-hoofddorp/">Salarisadministratie Hoofddorp </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-hoogeveen/">Salarisadministratie Hoogeveen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-hoorn/">Salarisadministratie Hoorn </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-katwijk/">Salarisadministratie Katwijk </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-leeuwarden/">Salarisadministratie Leeuwarden </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-leiden/">Salarisadministratie Leiden </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-leidschendam/">Salarisadministratie Leidschendam </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-lelystad/">Salarisadministratie Lelystad </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-maastricht/">Salarisadministratie Maastricht </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-middelburg/">Salarisadministratie Middelburg </Link><br /><br />
                </div>
                <div className="col-4 feature">
                  <h5>N-Z</h5>
                  <Link className="link mulberry" to="/salarisadministratie-nieuwegein/">Salarisadministratie Nieuwegein </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-nijmegen/">Salarisadministratie Nijmegen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-oss/">Salarisadministratie Oss </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-purmerend/">Salarisadministratie Purmerend </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-roermond/">Salarisadministratie Roermond </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-roosendaal/">Salarisadministratie Roosendaal </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-rotterdam/">Salarisadministratie Rotterdam </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-schiedam/">Salarisadministratie Schiedam </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-spijkenisse/">Salarisadministratie Spijkenisse </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-terneuzen/">Salarisadministratie Terneuzen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-tilburg/">Salarisadministratie Tilburg </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-utrecht/">Salarisadministratie Utrecht </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-veenendaal/">Salarisadministratie Veenendaal </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-venlo/">Salarisadministratie Venlo </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-vlaardingen/">Salarisadministratie Vlaardingen </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-voorburg/">Salarisadministratie Voorburg </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-westland/">Salarisadministratie Westland </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-zaandam/">Salarisadministratie Zaandam </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-zeist/">Salarisadministratie Zeist </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-zoetermeer/">Salarisadministratie Zoetermeer </Link><br /><br />
                  <Link className="link mulberry" to="/salarisadministratie-zwolle/">Salarisadministratie Zwolle </Link><br /><br />
                </div>
              </div>
              <p className="seo-link">Tags:&nbsp;
                <Link className="seo-link" to="/salarisadministratie-uitbesteden/">Salarisadministratie uitbesteden </Link>,
                <Link className="seo-link" to="/loonadministratie-uitbesteden/">Loonadministratie uitbesteden </Link>,
                <Link className="seo-link" to="/salarispakket/">Salarispakket </Link>,
                <Link className="seo-link" to="/salaris-software/">Salaris-software </Link>,
                <Link className="seo-link" to="/personeelsadministratie/">Personeelsadministratie </Link>,
                <Link className="seo-link" to="/salarisadministratie-zelf-doen/">Salarisadministratie-zelf-doen </Link>,
                <Link className="seo-link" to="/salarisverwerking/">Salarisverwerking </Link>,
                <Link className="seo-link" to="/loon-software/">Loon-software </Link>,
                <Link className="seo-link" to="/loonadministratie/">Loonadministratie </Link>,
                <Link className="seo-link" to="/loonstrook-maken/">Loonstrook maken </Link>,
                <Link className="seo-link" to="/employes-vs-nmbrs/">Employes vs. Nmbrs </Link>,
                <Link className="seo-link" to="/salarisadministratiekantoor/">Salarisadministratiekantoor</Link>,&nbsp;
                <Link className="seo-link" to="/loonadministrateur/">Loonadministrateur</Link>,&nbsp;
                <Link className="seo-link" to="/verloning/">Verloning</Link>,&nbsp;
                <Link className="seo-link" to="/salarisprogramma/">Salarisprogramma</Link>,&nbsp;
                <Link className="seo-link" to="/loonpakket/">Loonpakket</Link>,&nbsp;
                  <br></br><br></br>
                    <Link className="seo-link" to="/payroll-almelo/">Payroll Almelo </Link>,
                    <Link className="seo-link" to="/payroll-almere/">Payroll Almere </Link>,
                    <Link className="seo-link" to="/payroll-alkmaar/">Payroll Alkmaar </Link>,
                    <Link className="seo-link" to="/payroll-alphen-aan-den-rijn/">Payroll Alphen aan den Rijn </Link>,
                    <Link className="seo-link" to="/payroll-amersfoort/">Payroll Amersfoort </Link>,
                    <Link className="seo-link" to="/payroll-amstelveen/">Payroll Amstelveen </Link>,
                    <Link className="seo-link" to="/payroll-amsterdam/">Payroll Amsterdam </Link>,
                    <Link className="seo-link" to="/payroll-apeldoorn/">Payroll Apeldoorn </Link>,
                    <Link className="seo-link" to="/payroll-arnhem/">Payroll Arnhem </Link>,
                    <Link className="seo-link" to="/payroll-assen/">Payroll Assen </Link>,
                    <Link className="seo-link" to="/payroll-bergen-op-zoom/">Payroll Bergen op Zoom </Link>,
                    <Link className="seo-link" to="/payroll-breda/">Payroll Breda </Link>,
                    <Link className="seo-link" to="/payroll-capelle-aan-den-ijssel/">Payroll Capelle aan den IJssel </Link>,
                    <Link className="seo-link" to="/payroll-delft/">Payroll Delft </Link>,
                    <Link className="seo-link" to="/payroll-den-bosch/">Payroll Den Bosch </Link>,
                    <Link className="seo-link" to="/payroll-den-haag/">Payroll Den Haag </Link>,
                    <Link className="seo-link" to="/payroll-den-helder/">Payroll Den Helder </Link>,
                    <Link className="seo-link" to="/payroll-deventer/">Payroll Deventer </Link>,
                    <Link className="seo-link" to="/payroll-doetinchem/">Payroll Doetinchem </Link>,
                    <Link className="seo-link" to="/payroll-dordrecht/">Payroll Dordrecht </Link>,
                    <Link className="seo-link" to="/payroll-ede/">Payroll Ede </Link>,
                    <Link className="seo-link" to="/payroll-eindhoven/">Payroll Eindhoven </Link>,
                    <Link className="seo-link" to="/payroll-emmen/">Payroll Emmen </Link>,
                    <Link className="seo-link" to="/payroll-enschede/">Payroll Enschede </Link>,
                    <Link className="seo-link" to="/payroll-geleen/">Payroll Geleen </Link>,
                    <Link className="seo-link" to="/payroll-gouda/">Payroll Gouda </Link>,
                    <Link className="seo-link" to="/payroll-groningen/">Payroll Groningen </Link>,
                    <Link className="seo-link" to="/payroll-haarlem/">Payroll Haarlem </Link>,
                    <Link className="seo-link" to="/payroll-heerenveen/">Payroll Heerenveen </Link>,
                    <Link className="seo-link" to="/payroll-heerlen/">Payroll Heerlen </Link>,
                    <Link className="seo-link" to="/payroll-helmond/">Payroll Helmond </Link>,
                    <Link className="seo-link" to="/payroll-hengelo/">Payroll Hengelo </Link>,
                    <Link className="seo-link" to="/payroll-hilversum/">Payroll Hilversum </Link>,
                    <Link className="seo-link" to="/payroll-hoofddorp/">Payroll Hoofddorp </Link>,
                    <Link className="seo-link" to="/payroll-hoogeveen/">Payroll Hoogeveen </Link>,
                    <Link className="seo-link" to="/payroll-hoorn/">Payroll Hoorn </Link>,
                    <Link className="seo-link" to="/payroll-katwijk/">Payroll Katwijk </Link>,
                    <Link className="seo-link" to="/payroll-leeuwarden/">Payroll Leeuwarden </Link>,
                    <Link className="seo-link" to="/payroll-leiden/">Payroll Leiden </Link>,
                    <Link className="seo-link" to="/payroll-leidschendam/">Payroll Leidschendam </Link>,
                    <Link className="seo-link" to="/payroll-lelystad/">Payroll Lelystad </Link>,
                    <Link className="seo-link" to="/payroll-maastricht/">Payroll Maastricht </Link>,
                    <Link className="seo-link" to="/payroll-middelburg/">Payroll Middelburg </Link>,
                    <Link className="seo-link" to="/payroll-nieuwegein/">Payroll Nieuwegein </Link>,
                    <Link className="seo-link" to="/payroll-nijmegen/">Payroll Nijmegen </Link>,
                    <Link className="seo-link" to="/payroll-oss/">Payroll Oss </Link>,
                    <Link className="seo-link" to="/payroll-purmerend/">Payroll Purmerend </Link>,
                    <Link className="seo-link" to="/payroll-roermond/">Payroll Roermond </Link>,
                    <Link className="seo-link" to="/payroll-roosendaal/">Payroll Roosendaal </Link>,
                    <Link className="seo-link" to="/payroll-rotterdam/">Payroll Rotterdam </Link>,
                    <Link className="seo-link" to="/payroll-schiedam/">Payroll Schiedam </Link>,
                    <Link className="seo-link" to="/payroll-spijkenisse/">Payroll Spijkenisse </Link>,
                    <Link className="seo-link" to="/payroll-terneuzen/">Payroll Terneuzen </Link>,
                    <Link className="seo-link" to="/payroll-tilburg/">Payroll Tilburg </Link>,
                    <Link className="seo-link" to="/payroll-utrecht/">Payroll Utrecht </Link>,
                    <Link className="seo-link" to="/payroll-veenendaal/">Payroll Veenendaal </Link>,
                    <Link className="seo-link" to="/payroll-venlo/">Payroll Venlo </Link>,
                    <Link className="seo-link" to="/payroll-vlaardingen/">Payroll Vlaardingen </Link>,
                    <Link className="seo-link" to="/payroll-voorburg/">Payroll Voorburg </Link>,
                    <Link className="seo-link" to="/payroll-westland/">Payroll Westland </Link>,
                    <Link className="seo-link" to="/payroll-zaandam/">Payroll Zaandam </Link>,
                    <Link className="seo-link" to="/payroll-zeist/">Payroll Zeist </Link>,
                    <Link className="seo-link" to="/payroll-zoetermeer/">Payroll Zoetermeer </Link>,
                    <Link className="seo-link" to="/payroll-zwolle/">Payroll Zwolle </Link>

              </p>
            </div>

          </header>


        </main>
      </Layout>
    )
  }
}

export default SalarisadministratieKantorenPage
